
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background: '#6EFA8F';
}

.App {
  text-align: center;
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
}

@media (max-width: 720px) {
  html, body {
    overflow-x: hidden;
  }

  body {
    position: relative;
  }
}
